
import FilledCube from '@/assets/FilledCube.svg.vue'
import OutlinedCube from '@/assets/OutlinedCube.svg.vue'
import ArrowsSvg from '@/assets/viewer/Arrows.svg.vue'
import BoxSvg from '@/assets/viewer/Box.svg.vue'
import EyeSvg from '@/assets/viewer/Eye.svg.vue'
import HistorySvg from '@/assets/viewer/History.svg.vue'
import FloatingTabs from '@/components/FloatingTabs/FloatingTabs.vue'
import CubeLoader from '@/components/Loaders/CubeLoader.vue'
import {
  cameraMode,
  modelViewerStore,
  setCameraMode,
  setChangedItems,
  setAddedItems,
  setDeletedItems,
  removeSelection,
  setPrimaryModelNumber,
  setSecondaryModelNumber,
  setRenderContext,
  drawCompareView,
  clearSecondaryModel,
  removeActiveLoad,
  setCompareFadingLevel,
  resetCamera,
  isometricCamera,
  toggleLabels,
} from '@/store/modelViewer.store'
import {
  CsBreadcrumbs,
  CsBtn,
  CsBtnToggle,
  CsFlex,
  CsSlider,
  CsCompareDialog,
} from '@consteel/csuetify'
import Vue from 'vue'
import DisplayTab from './DisplayTab.vue'
import FilterTab from './FilterTab.vue'
import HistoryTab from './HistoryTab.vue'
import FailedToLoadModel from './FailedToLoadModel.vue'
import {
  DefaultLabel,
  LineLoadLabel,
  LoadTransferSurfaceLabel,
  NodalLoadLabel,
  StructuralMemberLabel,
  StructuralPlateLabel,
  SupportPointLabel,
  SurfaceLoadLabel,
} from './Labels'
import LoadTab from './LoadTab.vue'
import { SteelspaceModel } from '@/modules/model'
import { FloatingLabel } from '@/types'
import { CompareView, RenderContext } from '@consteel/straw'
import {
  HistoryItem,
  downloadHistoryItem,
  historyStore,
  resetHistoryError,
  CompareOptions,
} from '@/modules/history'

export default Vue.extend({
  name: 'HistoryCompareTab',
  components: {
    CsCompareDialog,
    CubeLoader,
    CsBreadcrumbs,
    CsBtn,
    CsBtnToggle,
    CsFlex,
    DefaultLabel,
    FilledCube,
    FilterTab,
    FloatingTabs,
    LineLoadLabel,
    LoadTransferSurfaceLabel,
    NodalLoadLabel,
    OutlinedCube,
    StructuralMemberLabel,
    StructuralPlateLabel,
    SupportPointLabel,
    SurfaceLoadLabel,
    HistoryTab,
    CsSlider,
    FailedToLoadModel,
  },
  props: {
    breadcrumbItems: {
      type: Array as () => Array<{
        text: string
        disabled: boolean
      }>,
    },
    floatingLabels: {
      type: Array as () => FloatingLabel[],
    },
    activeLoadId: String,
    model: Object as () => SteelspaceModel | null,
    historyItems: Array as () => HistoryItem[],
  },
  data() {
    return {
      showCompareDialog: true,
      selectedHistoryItemId: null as string | null,
      secondarySelectedHistoryItemId: null as string | null,
      selectToCompare: true,
    }
  },
  beforeDestroy() {
    setChangedItems(null)
    setAddedItems(null)
    setDeletedItems(null)
    clearSecondaryModel()
    setPrimaryModelNumber(null)
    setSecondaryModelNumber(null)
    removeSelection()
    setCompareFadingLevel(0)
  },
  computed: {
    labelVisibility(): boolean {
      return modelViewerStore.labelVisibility
    },
    viewControlVisibility(): boolean {
      return modelViewerStore.viewControlVisibility
    },
    cantLoad(): boolean {
      return modelViewerStore.cantLoad
    },
    errorMessage(): string {
      return modelViewerStore.errorMessage
    },
    historyLoading(): boolean {
      return historyStore.loading
    },
    modelLoading(): boolean {
      return modelViewerStore.currentViewLoading
    },
    fadingLevel: {
      get() {
        return modelViewerStore.compareFadingLevel
      },
      set(value: number) {
        setCompareFadingLevel(value)
      },
    },
    compareLabels(): Array<string> {
      return [
        this.selectedHistoryItem?.number || `#${this.historyItems.length + 1}`,
        this.secondarySelectedHistoryItem?.number || `#${this.historyItems.length + 1}`,
      ]
    },
    selectedHistoryItem(): HistoryItem | SteelspaceModel | undefined {
      if (this.selectedHistoryItemId == null) return

      if (this.model && this.selectedHistoryItemId === this.model.id) return this.model

      return this.historyItems.find((item) => item.id === this.selectedHistoryItemId)
    },
    secondarySelectedHistoryItem(): HistoryItem | SteelspaceModel | undefined {
      if (this.secondarySelectedHistoryItemId == null) return

      if (this.model && this.secondarySelectedHistoryItemId === this.model.id) return this.model

      return this.historyItems.find((item) => item.id === this.secondarySelectedHistoryItemId)
    },
    currModelIsShared(): boolean {
      return !this.model?.isOrigin
    },
    selectedCameraMode: {
      get() {
        return modelViewerStore.cameraMode
      },
      set(newVal: cameraMode) {
        setCameraMode(newVal)
      },
    },
    secondaryTabs(): Array<Record<string, unknown>> {
      const tabs = [
        {
          icon: HistorySvg,
          text: this.$t('History'),
          content: HistoryTab,
          props: {
            selectToCompare: this.selectToCompare,
            secondarySelected: this.secondarySelectedHistoryItemId,
            model: this.model,
            value: this.selectedHistoryItemId,
            items: this.historyItems,
            errorMessage: this.$t(historyStore.historyErrorMessage),
            errorTitle: this.$t(historyStore.historyErrorTitle),
            errorIcon: historyStore.historyErrorIcon,
            errorIconColor: historyStore.historyErrorIconColor,
            loading: this.historyLoading,
            hideEdit: true,
            selectable: false,
            hideOpen: true,
            hideCreate: true,
            hideRestore: true,
            hideDownload: true,
            hideDelete: true,
            compareVersionsButtonText: this.$t('New compare'),
          },
          listeners: {
            input: this.handleHistoryItemSelect,
            download: downloadHistoryItem,
            selectSecondary: this.handleHistoryItemSelectSecondary,
            errorCancel: resetHistoryError,
            clickCompareVersions: this.handleClickNewCompareVersions,
          },
        },
        {
          icon: BoxSvg,
          text: this.$t('Selection'),
          content: FilterTab,
        },
        {
          icon: ArrowsSvg,
          text: this.$t('Loads'),
          content: LoadTab,
        },
        {
          icon: EyeSvg,
          text: this.$t('Display'),
          content: DisplayTab,
          disabled: this.modelLoading,
          listeners: {
            toggleLabels,
          },
        },
      ]

      return tabs
    },
  },
  methods: {
    getSelectedCompareItemByIndex(index: number): string | undefined {
      if (this.selectedHistoryItemId == null || this.secondarySelectedHistoryItemId == null) return

      const compareItems = { 0: this.selectedHistoryItemId, 1: this.secondarySelectedHistoryItemId }

      return compareItems[index]
    },
    handleClickClose(): void {
      this.$emit('navigateToModel')
    },
    handleClickCompare({
      firstItemId,
      secondItemId,
      compareOptions,
    }: {
      firstItemId: string
      secondItemId: string
      compareOptions: CompareOptions
    }): void {
      setCompareFadingLevel(0)
      const firstDate =
        firstItemId === this.model?.id
          ? this.model.lastModificationDate
          : this.historyItems.find((item) => item.id === firstItemId)?.creationDate
      const secondDate =
        secondItemId === this.model?.id
          ? this.model.lastModificationDate
          : this.historyItems.find((item) => item.id === secondItemId)?.creationDate

      if (!firstDate || !secondDate) return

      if (firstDate > secondDate) {
        this.selectedHistoryItemId = secondItemId
        this.secondarySelectedHistoryItemId = firstItemId
      } else {
        this.selectedHistoryItemId = firstItemId
        this.secondarySelectedHistoryItemId = secondItemId
      }

      this.showCompareDialog = false

      this.$emit(
        'historyItemsCompare',
        this.selectedHistoryItemId,
        this.secondarySelectedHistoryItemId,
        compareOptions,
        async () => {
          await drawCompareView()
        }
      )

      this.$router.push({
        path: '/model/' + this.$route.params.modelId + '/compare',
        query: {
          current: this.selectedHistoryItemId,
          incoming: this.secondarySelectedHistoryItemId,
        },
      })
    },
    handleClickNewCompareVersions(): void {
      this.showCompareDialog = true
    },
    async handleHistoryItemSelectSecondary(historyItemId: string): Promise<void> {
      this.secondarySelectedHistoryItemId = historyItemId
    },
    async handleHistoryItemSelect(historyItemId: string): Promise<void> {
      this.selectedHistoryItemId = historyItemId
      this.$emit('historyItemSelect', historyItemId)
    },
    resetCamera,
    isometricCamera,
    setFocusToCanvas() {
      if (!modelViewerStore.cantLoad) {
        ;(this.$refs.canvas as HTMLCanvasElement).focus()
      }
    },
  },
  mounted(): void {
    const context = new RenderContext(
      this.$refs.canvas as HTMLCanvasElement,
      this.$refs.canvasParent as HTMLDivElement
    )
    context.RenderSecondaryScene()
    context.RenderGizmo()
    removeActiveLoad()
    setRenderContext(context)
  },
  created(): void {
    removeSelection()
    this.selectedHistoryItemId = (this.$route.query?.current as string) || this.model?.id || null
    this.secondarySelectedHistoryItemId =
      (this.$route.query?.incoming as string) || this.model?.id || null
  },
  watch: {
    fadingLevel(val: number) {
      const view = modelViewerStore.currentView
      if (!view) return
      ;(view as CompareView).SetFadeLevel(val / 100)
    },
    $route(newRoute) {
      this.selectedHistoryItemId = newRoute.query?.current || this.model?.id || null
      this.secondarySelectedHistoryItemId = newRoute.query?.incoming || this.model?.id || null
    },
  },
})
