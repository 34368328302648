var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "model-viewer-tab",
      attrs: { "fill-height": "", "fill-width": "", tabindex: "0" },
      on: { mouseover: _vm.setFocusToCanvas },
    },
    [
      _c(
        "div",
        {
          ref: "canvasParent",
          class: [
            "canvas-parent",
            "canvasZone",
            { activeGizmoLabels: _vm.viewControlVisibility },
          ],
          attrs: { "fill-width": "", "fill-height": "" },
        },
        [
          _vm.historyItems &&
          _vm.model &&
          _vm.selectedHistoryItem &&
          _vm.secondarySelectedHistoryItem
            ? _c("cs-compare-dialog", {
                attrs: {
                  persistent: "",
                  items: _vm.historyItems,
                  first: _vm.selectedHistoryItem,
                  second: _vm.secondarySelectedHistoryItem,
                  model: _vm.model,
                },
                on: {
                  compare: _vm.handleClickCompare,
                  close: _vm.handleClickClose,
                },
                model: {
                  value: _vm.showCompareDialog,
                  callback: function ($$v) {
                    _vm.showCompareDialog = $$v
                  },
                  expression: "showCompareDialog",
                },
              })
            : _vm._e(),
          _vm.cantLoad
            ? [
                _c("failed-to-load-model", {
                  attrs: { errorMessage: _vm.errorMessage },
                }),
              ]
            : [
                _c("canvas", { ref: "canvas", staticClass: "canvas" }),
                _vm._l(_vm.floatingLabels, function (item) {
                  return [
                    _c(item.name, {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !item.hidden,
                          expression: "!item.hidden",
                        },
                      ],
                      key: item.id,
                      tag: "component",
                      attrs: {
                        id: item.id,
                        model: item.model,
                        visibility: _vm.labelVisibility,
                        objectGuid: item.objectGuid,
                      },
                      on: { close: item.close },
                    }),
                  ]
                }),
                _c(
                  "cs-flex",
                  {
                    staticClass: "before-canvas",
                    attrs: {
                      vertical: "",
                      "fill-height": "",
                      "fill-width": "",
                      justify: "flex-start",
                    },
                  },
                  [
                    _vm.activeLoadId
                      ? _c("cs-breadcrumbs", {
                          staticClass: "pt-3 pb-0 px-3",
                          attrs: { items: _vm.breadcrumbItems },
                        })
                      : _vm._e(),
                    _c(
                      "cs-btn-toggle",
                      {
                        staticClass: "x-ray-toggle pa-1 mt-5",
                        attrs: { mandatory: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "slider-container" },
                          [
                            _c("cs-slider", {
                              attrs: {
                                min: 0,
                                max: 100,
                                rangeLabels: _vm.compareLabels,
                              },
                              model: {
                                value: _vm.fadingLevel,
                                callback: function ($$v) {
                                  _vm.fadingLevel = $$v
                                },
                                expression: "fadingLevel",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    this.$refs.canvas
                      ? _c(
                          "cs-flex",
                          {
                            class: [
                              "viewcube-controls",
                              { active: _vm.viewControlVisibility },
                            ],
                            style: `--scale: ${
                              this.$refs.canvas.height / 1255
                            }`,
                            attrs: { vertical: "" },
                          },
                          [
                            _c(
                              "cs-flex",
                              { staticClass: "mb-12", attrs: { gap: "9rem" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "button-container" },
                                  [
                                    _c(
                                      "cs-btn",
                                      {
                                        staticClass: "ma-0 pa-2",
                                        attrs: {
                                          color: "transparent",
                                          "min-width": 110,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.resetCamera.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("Reset"))),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "button-container" },
                                  [
                                    _c(
                                      "cs-btn",
                                      {
                                        staticClass: "ma-0 pa-2",
                                        attrs: {
                                          color: "transparent",
                                          "min-width": 110,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.isometricCamera.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("Isometric"))),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "cs-btn-toggle",
                              {
                                staticClass: "toggle-group pa-1 mb-4",
                                attrs: { mandatory: "", dense: "" },
                                model: {
                                  value: _vm.selectedCameraMode,
                                  callback: function ($$v) {
                                    _vm.selectedCameraMode = $$v
                                  },
                                  expression: "selectedCameraMode",
                                },
                              },
                              [
                                _c(
                                  "cs-btn",
                                  {
                                    staticClass: "ma-0 mr-1 pa-2",
                                    attrs: { color: "transparent" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Orthographic"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "cs-btn",
                                  {
                                    staticClass: "ma-0 pa-2",
                                    attrs: { color: "transparent" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Perspective"))),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    _vm.modelLoading
                      ? _c(
                          "cs-flex",
                          { staticClass: "loader-wrapper" },
                          [_c("cube-loader")],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.secondaryTabs
        ? _c("floating-tabs", {
            staticClass: "left-tabs",
            attrs: {
              tabItems: _vm.secondaryTabs,
              closeable: "",
              defaultClosed: false,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }